<template>
  <router-layout>
    <div class="page">
      <div class="inner section3">
        <van-loading color="#1989fa" type="spinner" class="loading" v-if="$store.state.loading" />
        <div v-else>
          <van-pull-refresh
            v-model="refreshing"
            @refresh="onRefresh"
            class="pull-refresh"
            v-show="hasData"
          >
            <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" offset="10">
              <ul v-show="hasData">
                <li v-for="(item,index) in List" :key="index" @click="toDetail(item)">
                  <div class="img" :style="{ 'background': 'url(' + item.image + ')'}"></div>
                  <div class="txt">
                    <div>
                      <h2>{{item.title}}</h2>
                    </div>
                    <div class="sumary">
                      <!-- <span>{{item.read}}阅读</span> -->
                      <span>{{item.time}}</span>
                    </div>
                  </div>
                </li>
              </ul>
            </van-list>
          </van-pull-refresh>
          <van-empty description="暂无内容" v-show="!hasData" />
        </div>
      </div>
    </div>
  </router-layout>
</template>
<script>
import authMixin from "@/mixin/mixin";
export default {
  data() {
    return {
      refreshing: false, //下拉刷新
      loading: false, //上拉加载
      finished: false,
      hasData: true,
      page: 0,
      List: [] //新闻
    };
  },
  mixins: [authMixin],
  created() {
    this.$store.commit("setLoading", true);
    this.getNews();
  },
  methods: {
    toDetail(item) {
      this.$router.push({
        path: "/newsDetail",
        query: {
          id: item.id
        }
      });
    },
    getNews() {
      this.$request({
        method: "get",
        url: "/cms/news/",
        params: {
          page: this.page
        }
      }).then(res => {
        this.$store.commit("setLoading", false);
        if (res.data && res.data.length) {
           this.hasData = true;
          res.data.forEach(item => {
            if (item.img) {
              let objdata = `{
              "id":"${item.id}",
              "title":"${item.title}",
              "image":"${item.img}",
              "time":"${item.create_time.split(" ")[0]}"
              }`;
              this.List.push(JSON.parse(objdata.replace(/[\r\n+]/g, '')))
            } else {
              let objdata = `{
              "id":"${item.id}",
              "title":"${item.title}",
              "image": "${require("@/assets/images/noimg.jpg")}",
              "time":"${item.create_time.split(" ")[0]}"
              }`;
              this.List.push(JSON.parse(objdata.replace(/[\r\n+]/g, '')))
            }
          });
          this.loading = false
          this.page = this.List[this.List.length - 1].id
        } else if (!res.data || !res.data.length && this.page == 0) {
          //暂无内容
          this.hasData = false;
        } else if (!res.data || res.data.length == 0) {
          this.finished = true;
        }
      });
    },
    onRefresh() {
      // this.$store.commit("setLoading", true);
      this.finished = false;
      this.loading = true;
      this.List = [];
      this.page = 0;
      this.getNews();
      this.refreshing = false;
    },
    onLoad() {
      this.getNews();
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../../assets/iconfont/iconfont.css";
.tohome {
  position: fixed;
  right: 0.1rem;
  bottom: 2rem;
  width: 1.2rem;
  height: 1.2rem;
  background-color: #fff;
  border-radius: 100%;
  box-shadow: 0.05rem 0.1rem 0.3rem rgba(0, 0, 0, 0.07);
  background-image: url(~@/assets/images/home.svg);
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-size: 0.6rem;
  z-index: 1;
}
.page {
  // height: 100vh;
}
.inner {
  padding: 0.35rem 0.3rem 0 0.3rem;
  box-sizing: border-box;
}
.swiper {
  border-radius: 0.15rem;
  margin-bottom: 0.34rem;
  img {
    width: 100%;
    border-radius: 0.15rem;
  }
}
.tabbar {
  .iconfont:before {
    font-size: 0.6rem;
  }
}
.menu li {
  text-align: center;
  float: left;
  width: 25%;
  margin-bottom: 0.5rem;
  p {
    font-size: 0.36rem;
    margin-top: 0.22rem;
  }
  > div {
    margin-left: auto;
    margin-right: auto;
    width: 1.2rem;
    height: 1.2rem;
    line-height: 1.2rem;
    border-radius: 0.12rem;
    span {
      font-size: 0.75rem;
      color: #fff;
    }
    img {
      width: 100%;
    }
  }
}

.line {
  height: 0.23rem;
  background: #f5f5f5;
}
.menu ul {
  height: auto;
  overflow: hidden;
}
.section2 {
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .t {
      font-size: 0.43rem;
    }
    .more {
      font-size: 0.38rem;
      color: #9e9e9e;
    }
  }
}
.section3 ul {
  height: auto;
  overflow: hidden;
  li {
    height: auto;
    overflow: hidden;
    padding: 0.35rem 0;
    border-bottom: 1px solid #ebedf0;
    .img {
      float: right;
      width: 2.8rem;
      height: 1.9rem;
      background-size: cover !important;
      background-repeat: no-repeat !important;
      background-position: center center !important;
    }
    .txt {
      margin-right: 3.5rem;
      height: 1.9rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      h2 {
        font-weight: normal;
        font-size: 0.38rem;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;

      }
      .sumary {
        color: #999;
        font-size: 0.34rem;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
        span {
          margin-right: 0.3rem;
        }
      }
    }
  }
}
.agreePop {
  overflow: hidden;
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
  padding: 0.4rem 0.3rem 0.35rem 0.3rem;
  .tit {
    font-size: 0.5rem;
    color: #222;
    margin-bottom: 0.7rem;
  }
  h2 {
    margin: 0.3rem 0;
    font-weight: normal;
    font-size: 0.42rem;
  }
  p {
    font-size: 0.36rem;
    color: #777;
    line-height: 0.65rem;
  }
  .foot {
    height: 0.6rem;
  }
}
</style>